import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.showing_modal),callback:function ($$v) {_vm.showing_modal=$$v},expression:"showing_modal"}},[_c(VCard,[(_vm.loading)?_c(VCardText,[_vm._v(" Cargando... "),_c(VProgressLinear,{staticClass:"mb-0",attrs:{"indeterminate":"","color":"black"}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Canal: "+_vm._s(_vm.company.name)+" ")]),_c(VCardText,[_c(VList,_vm._l((_vm.company),function(value,key,index){return _c('div',{key:("item_list_" + index)},[(value !== undefined && value !== null)?_c(VListItem,{attrs:{"two-line":""}},[(key == 'admin_company' || key == 'channel')?_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.parser[key]))]),_c(VListItemSubtitle,[(!value || !value.length)?_c('span',[_vm._v(_vm._s(key == 'admin_company'? 'Sin administradores' : 'Sin canales asociados'))]):_c('div',_vm._l((value),function(item,idx){return _c('div',{key:("item_company_" + idx)},[_vm._v(" - "+_vm._s(item.name)+" ")])}),0)])],1):_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.parser[key]))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.parseValue(key, value)))])],1)],1):_vm._e()],1)}),0)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CERRAR ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }