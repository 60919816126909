<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>Empresas</v-card-title>
      <CompaniesList></CompaniesList>
    </v-card>


  </div>
</template>

<script>

import AppCard from '@/@core/components/app-card/AppCard'
import AppCardBody  from '@/@core/components/app-card/AppCardBody'
import AppCardHeader  from '@/@core/components/app-card/AppCardHeader'
import CompaniesList from '@/components/Companies/CompaniesList';


export default {
 components: {
    AppCard,
    AppCardBody,
    AppCardHeader,
    CompaniesList,
},
 setup() {
    return {
      // icons
      icons: {
      },
    }
  },

}
</script>