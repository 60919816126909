<template>
  <div>
  <!-- list filters -->
  <v-card>
    <v-divider class="mt-4"></v-divider>

    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-model="searchQuery"
        @keyup="handleSearch"
        placeholder="Buscar por nombre de empresa y canal asociado"
        outlined
        hide-details
        dense
        class="user-search me-3 mb-4"
      >
      </v-text-field>

      <v-spacer></v-spacer>


      <div class="d-flex align-center flex-wrap">
        <v-btn
          color="primary"
          class="mb-4 me-3"
          v-if="getUserRole === 0"
          @click="$refs.company_form_modal.openModal()"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Agregar Empresa</span>
        </v-btn>

      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedRows"
      :headers="tableColumns"
      :page="current_page"
      :items="channelListTable"
      :options.sync="options"
      :server-items-length="totalChannelListTable"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      @update:page="changingCompanyPage($event)"
      @update:items-per-page="changingCompanyItemsPerPage($event)"
    >
      <!-- show-select -->

      <!-- NO data -->
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            No hay
            <strong>Empresas</strong>
            disponibles para esta búsqueda
          </v-list-item-title>
        </v-list-item>
      </template>

      <!-- name -->
      <template #[`item.name`]="{item}">
        <v-btn
          class="channel-btn"
          text
          @click="$refs.company_info_modal.open(item.id)"
        >
          {{ item.name }}
        </v-btn>
      </template>

      <!-- admin_company -->
      <template #[`item.credits`]="{item}">
        {{ item.credits? item.credits : '0' }}
      </template>

      <!-- admin_company -->
      <template #[`item.admin_company`]="{item}">
        <div v-if="item.admin_company.length" class="my-2">
          <v-chip
            v-for="(admin_company,idx) in item.admin_company" :key="`${admin_company}_${idx}`"
            small
            :color="resolveChipColorVariant()"
            :class="`${resolveChipColorVariant()}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ admin_company }}
          </v-chip>
        </div>
        <div v-else>-</div>
      </template>

      <!-- channels -->
      <template #[`item.channel`]="{item}">
        <div v-if="item.channel.length">
          <v-chip
            v-for="(channel,idx) in item.channel" :key="`${channel}_${idx}`"
            small
            :color="resolveChipColorVariant()"
            :class="`${resolveChipColorVariant()}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ channel }}
          </v-chip>
        </div>
        <div v-else>-</div>
      </template>

      <!-- edit -->
      <template #[`item.edit`]="{item}">
        <v-btn
          icon
          color="primary"
          @click="$refs.company_form_modal.openModal(item.id)"
        >
          <v-icon>
            {{ icons.mdiSquareEditOutline }}
          </v-icon>
        </v-btn>
      </template>

      <!-- delete -->
      <template #[`item.delete`]="{item}">
        <v-btn icon @click="$refs.delete_company_modal.open(item)">
          <v-icon class="error--text">
            {{ item.enabled? icons.mdiDeleteOutline : icons.mdiDeleteOutline }}
          </v-icon>
        </v-btn>
      </template>

      <!-- Google -->
      <template #[`item.google`]="{item}">
        <v-btn
          icon
          color="info"
          @click="connectGoogle(item.id)"
        >
          <v-icon>
            {{ icons.mdiLogin }}
          </v-icon>
        </v-btn>
        <v-tooltip top v-if="item.gmail">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-0" color="primary" dense v-bind="attrs" v-on="on">
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </template>
          <span>{{ item.gmail }}</span>
        </v-tooltip>
        <v-btn
          v-if="item.gmail"
          icon
          color="error"
          @click="openRevokeCredentials(item.id, item.gmail)"
        >
          <v-icon>
            {{ icons.mdiCloseCircleOutline }}
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>



    <company-form-modal
      ref="company_form_modal"
      :allUsers="allUsers"
      :allChannelList="allChannelList"
      @reload-company-list="getCompanies()"
    />

    <company-info-modal
      ref="company_info_modal"
      :allUsers="allUsers"
      :allChannelList="allChannelList"
      @reload-company-list="getCompanies()"
    />

    <delete-company-modal
      ref="delete_company_modal"
      @reload-company-list="getCompanies()"
    />

    <template>
      <v-dialog
      v-model="showingRevokeCredentials"
      width="auto"
      persistent
      >
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">
            ¿Está seguro de querer revocar las credenciales de Google del email {{revokeEmail}}?
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
          <v-spacer></v-spacer>

            <v-btn
            color="primary"
            text
            @click="closeRevokeCredentials()"
            >
              CANCELAR
            </v-btn>
            <v-btn
            color="error"
            @click="revokeCredentials(revokeId)"
            >
              Revocar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>


  </v-card>
  </div>
</template>

<script>
const debounce = require('debounce');

// eslint-disable-next-line object-curly-newline
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiSquareEditOutline,
  mdiLogin,
  mdiEyeOutline,
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import useUsersList from '../Messages/useUsersList'
import CompanyFormModal from './CompanyFormModal.vue'
import DeleteCompanyModal from './DeleteCompanyModal.vue'
import CompanyInfoModal from './CompanyInfoModal.vue';

export default {
data: () => {
  return {
    current_page: 1,
    page_size: 10,

    enabled_toggle_confirm_modal: false,

    allUsers: [],
    allChannelList: [],
    google_access_token: null,
    showingRevokeCredentials: false,
    revokeId: null,
    revokeEmail: ''
  }
},
beforeMount() {
  this.getGoogleToken()
},
mounted() {
  if(this.getSelectedCompany) {
    this.loading = true
    this.getCompanies()
  }
  this.getAllUsers()
  // this.getChannelList()
  if(this.google_access_token) {
    this.saveGoogleCredentials()
  }
},
methods: {
  openRevokeCredentials(id, email) {
    this.revokeId = id
    this.revokeEmail = email
    this.showingRevokeCredentials = true
  },

  closeRevokeCredentials() {
    this.revokeId = null
    this.revokeEmail = ''
    this.showingRevokeCredentials = false
  },

  async revokeCredentials() {
    try {
      const response = await this.$api.revokeGoogleCredentials(this.revokeId)
      this.closeRevokeCredentials()
      this.getCompanies()
    } catch(e) {
      console.log(e)
    }
  },

  setFilter() {
    this.current_page = 1
    this.getCompanies()
  },
  handleSearch: debounce(function (e) {
    this.current_page = 1
    this.getCompanies()
  }, 400),

  changingCompanyPage(e) {
    this.current_page = e
    this.getCompanies()
  },

  changingCompanyItemsPerPage(e) {
    this.page_size = e
    this.getCompanies()
  },

  // async getChannelList() {
  //   const channels = await this.$api.getChannelList()
  //   this.allChannelList = channels
  //   // console.log(channels);
  // },
  async getAllUsers() {
    const users = await this.$api.getUsersForCreateCompany()
    this.allUsers = users.results
  },

  async getCompanies() {
    this.loading = true

    const data = {
      page_size: this.page_size,
      page: this.current_page,
      search: this.searchQuery,
    }

    try {
      const response = await this.$api.getCompanies(data)
      this.channelListTable = response.results
      this.totalChannelListTable = response.count
    } catch (e) {
      console.log(e);
    } finally { this.loading = false }
  },

  async connectGoogle(companyId) {
    try {
      let form = {
        client_id: '304326620743-72r11g1q7kqbl1urgqj159r8n0fqt8b6.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/contacts+https://www.googleapis.com/auth/userinfo.profile+https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/calendar',
        client_secret: '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',
        response_type: 'code',
        redirect_uri: process.env.VUE_APP_GOOGLE_REDIRECT_URL,
        access_type: 'offline',
        include_granted_scopes: true,
        immediate: true
      }
      console.log(form)

      sessionStorage.setItem('company_id', JSON.stringify(companyId))

      const url = `https://accounts.google.com/o/oauth2/v2/auth?scope=${form.scope}&access_type=${form.access_type}&include_granted_scopes=${form.include_granted_scopes}&response_type=${form.response_type}&redirect_uri=${form.redirect_uri}&client_id=${form.client_id}&immediate=${form.immediate}`
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.click();
      console.log(url)
    } catch(e) {
      console.log(e)
    }
  },
  getGoogleToken() {
    let url = window.location
    this.google_access_token = new URLSearchParams(url.search).get('code');
    console.log(this.google_access_token)
  },
  async saveGoogleCredentials() {
    try {
      let form = {
        code: this.google_access_token
      }
      const companyId = sessionStorage.getItem('company_id')
      const response = await this.$api.saveGoogleCredentials(companyId, form)
      this.getCompanies()
      console.log(response)
    } catch(e) {
      console.log(e)
    }
  }
},
computed: {
  dateOptions() { return {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", hour12:"false", minute: "numeric", second: "numeric"}},
  getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  getUserRole() { return this.$store.getters['session/getUserRole']() },
},
watch: {
  getSelectedCompany (newValue) {
    if (newValue) {
      this.getCompanies()
    }
  }
},
components: {
  CompanyFormModal,
  DeleteCompanyModal,
  CompanyInfoModal,
},
setup() {
  const USER_APP_STORE_MODULE_NAME = 'app-user'

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })

  const {
    searchQuery,
    selectedRows,

  } = useUsersList()


  // New configurations

  const totalChannelListTable = ref(0)

  const tableColumns = [
    { text: 'EMPRESA', value: 'name', align: 'center', sortable: false },
    { text: 'ADMINISTRADOR', value: 'admin_company', align: 'center', sortable: false },
    { text: 'CREDITOS TOTALES', value: 'credits', align: 'center', sortable: false },
    { text: 'CANALES', value: 'channel', align: 'center', sortable: false },
    { text: 'EDITAR', value: 'edit', align: 'center', sortable: false },
    { text: 'ELIMINAR', value: 'delete', align: 'center', sortable: false },
    { text: 'CONECTAR CON GOOGLE', value: 'google', align: 'center', sortable: false },
  ]

  const loading = ref(false)

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const channelListTable = ref([])

  const resolveChipColorVariant = chip => {
    if (chip === 'red') return 'error'
    if (chip === 'green') return 'success'
    if (chip === 'violet') return 'primary'

    return 'primary'
  }


  // New configurations


  return {
    channelListTable,
    tableColumns,
    searchQuery,
    totalChannelListTable,
    loading,
    options,
    selectedRows,
    resolveChipColorVariant,

    icons: {
      mdiDeleteOutline,
      mdiPlus,
      mdiCheckCircleOutline,
      mdiCloseCircleOutline,
      mdiSquareEditOutline,
      mdiLogin,
      mdiEyeOutline,
    },
  }
},
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
