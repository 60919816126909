
<template>
  <v-dialog
      v-model="showingCompanyChannelModal"
      width="auto"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          ¿Estás seguro de eliminar la empresa '{{ company.name }}'?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary" 
            text 
            @click="close()"
          >
            CANCELAR
          </v-btn>         
          <v-btn
            color="error"
            @click="deleteCompany()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
  
  <script>
  
  export default {

    methods: {
      open(company) {
        this.showingCompanyChannelModal = true
        this.company = company
      },

      close() {
        this.showingCompanyChannelModal = false
      },


      async deleteCompany() {
        try {
          const response = await this.$api.deleteCompany(this.company.id)
          // console.log(response);

          const companies = await this.$api.getCompaniesList()
          this.$store.dispatch('session/setAllCompaniesList', companies)
          this.$emit('reload-company-list')
          this.close()
        } catch (e) {
          console.log(e)
        }
      },

    },
    data: () => {
      return {
        showingCompanyChannelModal: false,
        company: {},
      }
    },
    computed: {
    },
  }
  </script>
  