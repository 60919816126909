import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Buscar por nombre de empresa y canal asociado","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[(_vm.getUserRole === 0)?_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.company_form_modal.openModal()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Agregar Empresa")])],1):_vm._e()],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"page":_vm.current_page,"items":_vm.channelListTable,"options":_vm.options,"server-items-length":_vm.totalChannelListTable,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.changingCompanyPage($event)},"update:items-per-page":function($event){return _vm.changingCompanyItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No hay "),_c('strong',[_vm._v("Empresas")]),_vm._v(" disponibles para esta búsqueda ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"channel-btn",attrs:{"text":""},on:{"click":function($event){return _vm.$refs.company_info_modal.open(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.credits",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.credits? item.credits : '0')+" ")]}},{key:"item.admin_company",fn:function(ref){
var item = ref.item;
return [(item.admin_company.length)?_c('div',{staticClass:"my-2"},_vm._l((item.admin_company),function(admin_company,idx){return _c(VChip,{key:(admin_company + "_" + idx),staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveChipColorVariant()) + "--text"),attrs:{"small":"","color":_vm.resolveChipColorVariant()}},[_vm._v(" "+_vm._s(admin_company)+" ")])}),1):_c('div',[_vm._v("-")])]}},{key:"item.channel",fn:function(ref){
var item = ref.item;
return [(item.channel.length)?_c('div',_vm._l((item.channel),function(channel,idx){return _c(VChip,{key:(channel + "_" + idx),staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveChipColorVariant()) + "--text"),attrs:{"small":"","color":_vm.resolveChipColorVariant()}},[_vm._v(" "+_vm._s(channel)+" ")])}),1):_c('div',[_vm._v("-")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$refs.company_form_modal.openModal(item.id)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiSquareEditOutline)+" ")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.delete_company_modal.open(item)}}},[_c(VIcon,{staticClass:"error--text"},[_vm._v(" "+_vm._s(item.enabled? _vm.icons.mdiDeleteOutline : _vm.icons.mdiDeleteOutline)+" ")])],1)]}},{key:"item.google",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.connectGoogle(item.id)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiLogin)+" ")])],1),(item.gmail)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-0",attrs:{"color":"primary","dense":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.gmail))])]):_vm._e(),(item.gmail)?_c(VBtn,{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.openRevokeCredentials(item.id, item.gmail)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiCloseCircleOutline)+" ")])],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('company-form-modal',{ref:"company_form_modal",attrs:{"allUsers":_vm.allUsers,"allChannelList":_vm.allChannelList},on:{"reload-company-list":function($event){return _vm.getCompanies()}}}),_c('company-info-modal',{ref:"company_info_modal",attrs:{"allUsers":_vm.allUsers,"allChannelList":_vm.allChannelList},on:{"reload-company-list":function($event){return _vm.getCompanies()}}}),_c('delete-company-modal',{ref:"delete_company_modal",on:{"reload-company-list":function($event){return _vm.getCompanies()}}}),[_c(VDialog,{attrs:{"width":"auto","persistent":""},model:{value:(_vm.showingRevokeCredentials),callback:function ($$v) {_vm.showingRevokeCredentials=$$v},expression:"showingRevokeCredentials"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6 grey lighten-2"},[_vm._v(" ¿Está seguro de querer revocar las credenciales de Google del email "+_vm._s(_vm.revokeEmail)+"? ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeRevokeCredentials()}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.revokeCredentials(_vm.revokeId)}}},[_vm._v(" Revocar ")])],1)],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }