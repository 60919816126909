<template>
  <v-card-title class="app-card-header">
    <slot></slot>
  </v-card-title>
</template>

<script>
export default {}
</script>

<style>
</style>
