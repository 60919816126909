
<template>
  <v-dialog
    v-model="showing_modal"
    width="500"
  >
    <v-card>
      <v-card-text v-if="loading">
        Cargando...
        <v-progress-linear
          indeterminate
          color="black"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>

      <div v-show="!loading">
        <v-card-title class="text-h5 grey lighten-2">
          Canal: {{ company.name }}
        </v-card-title>
  
        <v-card-text>
          <v-list>
            
            <div v-for="(value, key, index) in company" :key="`item_list_${index}`">
              <v-list-item two-line v-if="value !== undefined && value !== null">
  
                <v-list-item-content v-if="key == 'admin_company' || key == 'channel'">
                  <v-list-item-title>{{ parser[key] }}</v-list-item-title>
  
                  <v-list-item-subtitle>
                    <span v-if="!value || !value.length">{{ key == 'admin_company'? 'Sin administradores' : 'Sin canales asociados' }}</span>
  
                    <div v-else>
                      <div v-for="(item, idx) in value" :key="`item_company_${idx}`">
                        - {{ item.name}}
                      </div>
                      
                    </div>
                  </v-list-item-subtitle>
  
                </v-list-item-content>
  
                <v-list-item-content v-else>
                  <v-list-item-title>{{ parser[key] }}</v-list-item-title>
                  <v-list-item-subtitle>{{ parseValue(key, value) }}</v-list-item-subtitle>
                </v-list-item-content>
                
              </v-list-item>
            </div>
  
          </v-list>
  
        </v-card-text>
        <v-divider></v-divider>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">
            CERRAR
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
  
  <script>
  export default {
    data: () => {
      return {
        showing_modal: false,
        loading: true,
        company: {},
      }
    },
    mounted() {
    },
    props: {
      allUsers: {
        type: Array,
        required: true
      },
      allChannelList: {
        type: Array,
        required: true
      },
    },
    methods: {
      getAdminName(id) {
        const founded = this.allUsers.find(el => el.id == id)
        return founded?.user
      },
      getChannelName(id) {
        const founded = this.allChannelList.find(el => el.id == id)
        return founded?.name
      },
      parseValue(key, value) {
        if (key == 'status') return value? 'Activo' : 'No activo'
        if (key == 'enabled') return value? 'Funciona' : 'No funciona'
        if (value === false) return 'No'
        if (value === true) return 'Si'

        return value
      },
      async open(id) {
        // console.log(item)
        this.loading = true
        try {
          const item = await this.$api.getCompany(id)
          this.company = item
          
          this.loading = false
          this.showing_modal = true
          console.log(item)
        } catch (e) {
          console.log(e);
        }

      },

      close() {
        this.showing_modal = false;
        this.loading = true
        this.company = {}
      },

    },
    computed: {
      parser() {
        const parser = { 
          id: 'ID',
          name: 'Nombre',
          open: 'Horario de apertura',
          close: 'Horario de cierre',
          credits: 'Créditos',
          hidden_message: 'Mensaje oculto',
          hidden_message_body: 'Contenido del mensaje oculto',
          limit_day: 'Día límite',
          limit: 'Límite de mensajes',
          channel: 'Canales asociados',
          admin_company: 'Administradores',
          minim_wait: 'Espera mínima',
          maxim_wait: 'Espera máxima',
          minim_wait_campaign: 'Espera mínima para mensajes de campaña',
          maxim_wait_campaign: 'Espera máxima para mensajes de campaña',
          break_time_max: 'Tiempo máximo de descanso (en minutos)',
          break_time_min: 'Tiempo minimo de descanso (en minutos) ',
          messages_by_block: 'Mensajes por bloque',
          has_diffusion_time: 'Tiene tiempo de difusión'
        }
        return parser
      }
    },
  }
  </script>
  